import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent
{
	public ticketId = '';

	public isFormValid(): boolean
	{
		if (!this.ticketId)
			return false;

		let ticketId = this.ticketId.toString();

		if (ticketId.length < 6)
			return false;

		const checkDigit = parseInt(ticketId.substr(ticketId.length - 1), 10);
		const validCheckDigit = this.calcCRC8(ticketId.substr(0, ticketId.length - 1));

		return checkDigit === validCheckDigit;
	}

	private calcCRC8(ticketId: string): number
	{
		const table =
			[0x0, 0x4, 0x2, 0x2, 0x1, 0x5, 0x3, 0x3,
				0x2, 0x2, 0x4, 0x0, 0x3, 0x3, 0x5, 0x1,
				0x3, 0x3, 0x1, 0x5, 0x2, 0x2, 0x0, 0x4,
				0x5, 0x1, 0x3, 0x3, 0x4, 0x0, 0x2, 0x2,
				0x3, 0x3, 0x5, 0x1, 0x2, 0x2, 0x4, 0x0,
				0x1, 0x5, 0x3, 0x3, 0x0, 0x4, 0x2, 0x2,
				0x4, 0x0, 0x2, 0x2, 0x5, 0x1, 0x3, 0x3,
				0x2, 0x2, 0x0, 0x4, 0x3, 0x3, 0x1, 0x5,
				0x6, 0x8, 0x0, 0x4, 0x7, 0x9, 0x1, 0x5,
				0x4, 0x0, 0x8, 0x6, 0x5, 0x1, 0x9, 0x7,
				0x1, 0x5, 0x7, 0x9, 0x0, 0x4, 0x6, 0x8,
				0x9, 0x7, 0x5, 0x1, 0x8, 0x6, 0x4, 0x0,
				0x5, 0x1, 0x9, 0x7, 0x4, 0x0, 0x8, 0x6,
				0x7, 0x9, 0x1, 0x5, 0x6, 0x8, 0x0, 0x4,
				0x8, 0x6, 0x4, 0x0, 0x9, 0x7, 0x5, 0x1,
				0x0, 0x4, 0x6, 0x8, 0x1, 0x5, 0x7, 0x9,
				0x2, 0x2, 0x0, 0x4, 0x3, 0x3, 0x1, 0x5,
				0x4, 0x0, 0x2, 0x2, 0x5, 0x1, 0x3, 0x3,
				0x1, 0x5, 0x3, 0x3, 0x0, 0x4, 0x2, 0x2,
				0x3, 0x3, 0x5, 0x1, 0x2, 0x2, 0x4, 0x0,
				0x5, 0x1, 0x3, 0x3, 0x4, 0x0, 0x2, 0x2,
				0x3, 0x3, 0x1, 0x5, 0x2, 0x2, 0x0, 0x4,
				0x2, 0x2, 0x4, 0x0, 0x3, 0x3, 0x5, 0x1,
				0x0, 0x4, 0x2, 0x2, 0x1, 0x5, 0x3, 0x3,
				0x0, 0x4, 0x6, 0x8, 0x1, 0x5, 0x7, 0x9,
				0x8, 0x6, 0x4, 0x0, 0x9, 0x7, 0x5, 0x1,
				0x7, 0x9, 0x1, 0x5, 0x6, 0x8, 0x0, 0x4,
				0x5, 0x1, 0x9, 0x7, 0x4, 0x0, 0x8, 0x6,
				0x9, 0x7, 0x5, 0x1, 0x8, 0x6, 0x4, 0x0,
				0x1, 0x5, 0x7, 0x9, 0x0, 0x4, 0x6, 0x8,
				0x4, 0x0, 0x8, 0x6, 0x5, 0x1, 0x9, 0x7,
				0x6, 0x8, 0x0, 0x4, 0x7, 0x9, 0x1, 0x5];

		let crc = 0;
		for (const char of ticketId)
		{
			const digit = parseInt(char, 10);

			const index = crc ^ digit;
			crc = table[index];
		}

		return crc;
	}

	public startCall()
	{
		if (!this.ticketId)
			return;

		let rawTicketId = this.ticketId.toString();
		rawTicketId = rawTicketId.substr(0, rawTicketId.length - 1);

		const ticketId = parseInt(rawTicketId);
		if (isNaN(ticketId))
		{
			alert('Invalid ticket number');
			return;
		}

		const server = environment.servers.find(s => ticketId >= s.min && ticketId <= s.max);
		if (!server)
		{
			alert('Invalid ticket number');
			return;
		}

		window.location.href = server.url + '/g?' + this.ticketId;
	}
}
