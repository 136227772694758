<form class="flex flex-col flex-align-center flex-justify-center" autocomplete="off">

	<div class="flex flex-col flex-align-center flex-justify-center">
		<img src="/assets/logo.png" />
	</div>

	<div class="flex flex-col flex-align-center flex-justify-start">
		<mat-form-field>
			<input name="ticketId" matInput autofocus placeholder="Ticket ID" type="number" [(ngModel)]="ticketId" (keydown.enter)="startCall()">
		</mat-form-field>
		
		<button mat-fab color="primary" (click)="startCall()" [disabled]="!isFormValid()"><img src="/assets/call.png" /></button>
	</div>

</form>